.faq-parent{
    margin-top: 150px;
    width: 100%;
}

@media screen and (max-width: 768px) {
   .faq-parent{
    padding: 0px;
    /* margin: 10px; */
    width: 100%;
   }
}