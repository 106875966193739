.enquiry-parent{
    margin-top: 120px;
    padding: 10px 40px;
}
.enquiry-btn{
    background-color: rgb(10, 170, 170);
      color: whitesmoke;
  }
  .enquiry-btn:hover {
    background-color: grey;
    transition: .3s;
    color: whitesmoke;
  }
.container-fluid {
    padding: 20px;
  }
  .container-parent{
    padding: 0px;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  @media screen and (max-width: 768px) {
    .enquiry-parent{
        padding: 0px;
    }
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .container-fluid {
        width: 100%;
        padding: 20px;
      }
  }
  
  @media screen and (max-width: 576px) {
    .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .justify-content-lg-center {
      justify-content: center;
    }
  
    .enquiry-btn {
      width: 100%;
    }
  }
  
