.site-header{
    margin-top: 130px;
    text-align: center;
}
.main-content__body{
    text-align: center;
    margin: 60px 30px;

}
.main-content__checkmark{
    font-size: 60px;
}