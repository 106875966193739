.teamPage {
  margin-top: 150px;
  padding: 0px 60px;
}
.team_card{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.team_card-content button{
  background-color: #B89F66;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  padding: 10px 20px;
  margin-left: 60px;
}
.teamCard {
  background-color: #F3F2EE;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 600px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 50px;
}
.teamCard:hover {
  -webkit-box-shadow: 0 10px 25px rgba(47, 60, 78, 0.15) !important;
          box-shadow: 0 10px 25px rgba(47, 60, 78, 0.15) !important;
  background: #ffffff;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  border-color: transparent !important;
  transition: .3s;
}
.dropdown-menu li{
  cursor: pointer;
}


.teamCard img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.team_card-content {
  padding: 20px;
}

.name {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.qualification, .specialization {
  margin-bottom: 10px;
}
.team_heading h1{
  text-align: center;
}
.name{
  text-transform: uppercase;
}
.form_bookbtn{
  background-color: rgb(10, 170, 170);
    color: whitesmoke;
}
.form_bookbtn:hover {
  background-color: grey;
  transition: .3s;
  color: whitesmoke;
}
@media only screen and (max-width: 600px) {
  .teamPage {
    padding: 0px;
  }
  .team_heading h1{
    text-align: left;
    margin-bottom: -30px;
    padding: 10px;
  }
  .team_card {
    flex-direction: column;
    align-items: center;
  }
  .teamCard {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }

  .teamCard img {
    width: 110px;
    height: 110px;
  }
  .team_card-content {
    padding: 0px;
  }
  .button{
    font-size: 16px;
    background-color: #B89F66;
    font-weight: 600;
  }
}

