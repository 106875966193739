.te_parent{
    margin-top: 130px;
}
:root {
    --orange: #fd9940;
    --darkorange: #dd7d25;
    --platinum: #e5e5e5;
    --black: #2b2d42;
    --white: #fff;
    --thumb: #edf2f4;
}


.container {
    max-width: 1400px;
}

h2 {
    font-size: 32px;
    margin-bottom: 1em;
}

.cards {
    display: grid;
    grid-auto-columns: 100%;
    grid-column-gap: 10px;
    grid-auto-flow: column;
    padding: 25px 0px;
    list-style: none;
    overflow-x: scroll;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
}

.cards::-webkit-scrollbar {
    height: 12px;
}

.cards::-webkit-scrollbar-thumb,
.cards::-webkit-scrollbar-track {
    border-radius: 92px;
}

.cards::-webkit-scrollbar-thumb {
    background: var(--darkorange);
}

.cards::-webkit-scrollbar-track {
    background: var(--thumb);
}



.card {

    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
    transition: all 0.2s;
    cursor: text;
}

.card .card-title {
    font-size: 20px;
}

.card .card-content {
    margin: 20px 0;
    max-width: 100%;
}


.card .card-link {
    display: inline-block;
    text-decoration: none;
    color: black;
    padding: 6px 12px;
    border-radius: 8px;
    transition: background 0.2s;
    cursor: text;
}



@media (min-width: 500px) {
    .cards {
        grid-auto-columns: calc(50% - 10px);
        grid-column-gap: 20px;
    }
}

@media (min-width: 700px) {
    .cards {
        grid-auto-columns: calc(calc(100% / 3) - 20px);
        grid-column-gap: 30px;
    }
}

@media (min-width: 1100px) {
    .cards {
        grid-auto-columns: calc(25% - 30px);
        grid-column-gap: 40px;
    }
}