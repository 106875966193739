.banner {
    width: 100%;
    height: 700px;
    /* margin-top: 100px; */
    background-image: url('../Resources/homeBanner.jpg');
    position: relative;
    background-position: center;
    background-size: cover;  
}

.banner-parent {
    display: flex;
    flex-wrap: wrap;  
    position: absolute;
    bottom: -120px;  
    left: 20px; 
    right: 20px;  
}

.banner-child-1,
.banner-child-2 {
    width: calc(40% - 20px);  
    margin-bottom: 20px;  
}

.banner-child-1 {
    background-color: rgb(10, 170, 170);
    overflow: hidden;
}

.banner-child-1:hover {
    cursor: pointer;
    transition: .3s;
}

.banner-child-2 {
    background-color: #0B406A;
}

.banner-child-2:hover {
    cursor: pointer;
    background-color: #044587;
    transition: .3s;
}

.banner-child-1 h4,
.banner-child-2 h4 {
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 28px;
    padding: 20px;
}

.banner-child-1 p,
.banner-child-2 p {
    padding: 20px;
    color: white;
    letter-spacing: 1px;
}

.banner-child-1 ul,
.banner-child-2 ul {
    list-style: none;
    color: white;
    padding: 0;
}

.banner-child-1 li,
.banner-child-2 li {
    font-size: 20px;
    padding-left: 20px;  
    text-align: justify;
    /* margin-top: 0px; */

}

.banner-child-1 li:hover,
.banner-child-2 li:hover {
    font-weight: 700;
    color: #0B406A;  
}


@media screen and (max-width: 768px) {
    .banner {
        height: 500px; 
        margin-top: 30px;  
    }
    
    .banner-parent {
        left: 10px;  
        right: 10px; 
        bottom: 10px;  
        top: 250px;
        flex-direction: wrap;  
        align-items: center;  
    }
    
    .banner-child-1,
    .banner-child-2 {
        width: calc(100% - 20px);  
    }
}

.about-section {
    width: 100%;
    margin-top: 100px;
    padding: 30px 70px;  
}

.about-parent h1 {
    font-size: 32px;
    font-weight: 700;
    font-stretch: 100%;
    text-decoration: none solid rgb(85, 74, 88);
    text-align: start;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    
}

.about-parent p {
    letter-spacing: 1px;
    color: black;
    font-size: 20px;
}

.about-parent-2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;  
    justify-content: space-between;
    margin-top: 40px;
}

.mission, .vision {
    width: calc(50% - 10px);  
    margin-bottom: 20px;  
    border-radius: 10px;
}

.mission {
    background-color: #0B406A;
}

.mission:hover {
    transition: .3s;
    background-color: #064381;
}

.mission h1 {
    color: white;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 600;
    padding: 20px;
}

.mission p {
    color: whitesmoke;
    letter-spacing: 2px;
    padding: 0px 20px;  
    font-size: 20px;
}

.vision {
    background-color: rgb(9, 145, 145);
}

.vision h1 {
    color: white;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
    padding: 20px;
}

.vision p {
    color: whitesmoke;
    letter-spacing: 2px;
    padding: 0px 20px;  
    font-size: 20px;
}

.vision:hover {
    transition: .3s;
    background-color: rgb(4, 108, 108);
}

@media screen and (max-width: 768px) {
    .about-parent{
        margin-top: 500px;
        padding: 0px;
    }  
    .about-parent blockquote{
        margin-left: -10px;
        font-size: 14px;
        margin-top: 0px;
        color: black;
    }
    .about-parent-2 {
        flex-direction: column; 
        align-items: center; 
    }

    .mission, .vision {
        width: 100%;
        text-align: center; 
    }
    .why-choose-section h1,
    .why-choose-section-parent {
        padding: 0px; 
    }
    
    .choose-section-list li {
        font-size: 18px; 
    }
    
    .choose-section-list p {
        font-size: 14px;
    }
    .about-section{
        padding: 30px 20px;
    }
    .why-choose-section{
        width: 100%;
        padding: 10px;
    }
    .about-parent h1{
        margin-top: 400px;

    }
   
   
}

.why-choose-section {
    width: 100%;
    height: auto; 
    padding: 10px 70px;
}

.why-choose-section h1 {
    letter-spacing: 1px;
    color: #0B406A;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    font-weight: bold; 
    font-size: 28px; 
}

.why-choose-section-parent {
    padding: 10px 20px; 
    width: 100%;
    height: auto; 
}

.choose-section-list {
    list-style: none;
    padding: 0;
}

.choose-section-list li {
    font-weight: 700;
    font-size: 20px; 
    letter-spacing: 1px;
    color: #0B406A;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    margin-bottom: 10px; 
}

.choose-section-list p {
    font-size: 16px; 
    letter-spacing: 1px;
    color: rgb(63, 62, 62);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 
    /* padding: 0px 70px; */
    margin-bottom: 20px; 
}

@media screen and (max-width: 600px) {
    .why-choose-section h1,
    .why-choose-section-parent {
        padding: 0px; 
    }
    
    .choose-section-list li {
        font-size: 18px; 
    }
    
    .choose-section-list p {
        font-size: 14px;
    }
    .about-section{
        padding: 30px 20px;
    }
    .why-choose-section{
        width: 100%;
        padding: 10px;
    }
    .about-parent h1{
        margin-top: 400px;

    }
}
