.parent{
    margin-top: 120px;
}
.mission p{
    letter-spacing: 0px;
    text-align: justify;
}
.vision p{
    letter-spacing: 0px;
    text-align: justify;
}

@media screen and (max-width: 600px) {
    .parent{
        margin-top: -400px;
    }
    .about-section{
        width: 100%;
        padding: 10px;
    }
}