.navbar {
    background-color: #0B406A;
    width: 100%;
}

.nav_item_stuff{
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}
.nav_item_stuff_brand{
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
}
.nav_item_stuff:hover{
    color: goldenrod;
}
.login_btn{
    background-color: #F4B081;
    color: black;
    padding: 15px 10px;
    /* width: max-content; */
    font-size: 14px;
    letter-spacing: 1px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    margin-right: 10px;
    margin-left: 10px;
}
.login_btn:hover{
    background-color: grey;
    transition: .3s;
    color: goldenrod;
}
blockquote {
    text-align:center;
    text-transform: none;
    margin-top: -45px;
    font-size: 23px;
    letter-spacing: 1px;
    margin-left: 208px;
    color: white;
    font-weight: 600;
}
.navbar_logo_tagline{
    font-size: 10px;
    margin-top: -20px;
}
.navbar_stuff_parent{
    text-align: center;
    margin-left: 30px;
    float: right;
}
.book-btn {
    background-color: rgb(10, 170, 170);
    color: whitesmoke;
    padding: 15px 10px;
    width: max-content;
    font-size: 14px;
    letter-spacing: 1px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    margin-right: 10px;
    margin-left: 10px;
}

.book-btn:hover {
    background-color: grey;
    transition: .3s;
    color: whitesmoke;
}
.brand_img{
    height: 70px;
    width: 90px;
    margin-left: 100px;
}


@media screen and (max-width: 620px) {
    .nav_item_stuff_brand{
        font-size: 24px;
        letter-spacing: 1px;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        margin-left: 30px;
    }
    blockquote {
        text-align:center;
        text-transform: none;
        margin-top: -45px;
        font-size: 18px;
        letter-spacing: 0.2px;
        margin-left: 110px;
        color: white;
    }
    .navbar_stuff_parent{
        text-align: left;
        margin-left: 0px;
        float: left;
    }
    .book-btn{
        margin-left: 0px;
    }
    .brand_img{
        height: 70px;
        width: 80px;
        margin-left: 0px;
    }
    
}
@media screen and (max-width: 364px) {
    .nav_item_stuff_brand{
        font-size: 24px;
        letter-spacing: 1px;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        margin-left: 20px;
    }
    blockquote {
        text-align:center;
        text-transform: none;
        margin-top: -40px;
        font-size: 18px;
        letter-spacing: 0.2px;
        margin-left: 100px;
        color: white;
    }
    .navbar_stuff_parent{
        text-align: left;
        margin-left: 0px;
        float: left;
    }
    .book-btn{
        margin-left: 0px;
    }
    .brand_img{
        height: 70px;
        width: 80px;
        margin-left: 0px;
    }
    
}