
.home-banner {
    width: 100%;
    background-color: rgb(10, 170, 170);
    padding: 40px 20px; 
    text-align: justify;
}

.home-banner p {
    text-align: center;
    color: white;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 0.6px;
    font-size: 24px; 
    font-weight: 700;
    margin-bottom: 20px; 
    padding-top: -50px;

}

.home-banner blockquote {
    text-align: justify;
    text-align: center;
    color: white;
    letter-spacing: 2px;
    font-size: 18px;  
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 0px;
}

.home-banner-buttons {
    text-align: center;
}

.home-banner-buttons button {
    background-color: #0B406A;
    color: whitesmoke;
    padding: 15px 10px;
    width: 200px;
    font-size: 16px;  
    letter-spacing: 2px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;
    margin-right: 20px;
}

.home-banner-buttons button:hover {
    background-color: grey;
    transition: .3s;
    color: whitesmoke;
}

@media screen and (max-width: 768px) {
    .home-banner p {
        font-size: 20px;  
    }
    
    .home-banner blockquote {
        font-size: 16px;  
        text-align: center;
        margin: 0px;
    }
    
    .home-banner-buttons button {
        font-size: 14px;  
        width: 150px;  
    }
}
