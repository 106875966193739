.servicePage{
   background-color: white;
   margin-top: 150px;
}
#contact{
    margin-top: 0px;
}
.contact-area {
   
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width:768px) {
    .contact {
        margin-bottom: 60px;
    }
}

.contact input {
    background: #fff;
    margin-top: 10px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #232434;
    font-size: 16px;
    height: 60px;
    padding: 10px;
    width: 100%;
    font-family: 'poppins', sans-serif;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.contact textarea {
    background: #fff;
    margin-top: 10px;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #232434;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    font-family: 'poppins', sans-serif;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.contact input:focus {
    background: #fff;
    border: 1px solid #fff;
    color: #232434;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 none;
}

.contact textarea:focus {
    background: #fff;
    border: 1px solid #fff;
    color: #232434;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 none;
}

.form-control::placeholder {
    color: #232434;
    opacity: 1;
}

.btn-contact-bg {
    margin-top: 10px;
    border-radius: 30px;
    color: #fff;
    outline: medium none !important;
    padding: 15px 27px;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    /* background: #7564e5; */
    font-family: 'poppins', sans-serif;
    cursor: pointer;
    width: 100%;
    background-color: rgb(10, 170, 170);
    color: whitesmoke;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 1px;
}

.btn-contact-bg:hover,
.btn-contact-bg:focus {
    background: #232434;
    color: #fff;
}


.single_address {
    margin-bottom: 10px;
    padding-left: 40px;
}

@media only screen and (max-width:768px) {
    .single_address {
        padding-left: 0px;
    }
}

.single_address i {
    background: #f6f6f6;
    color: #0B406A;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    float: left;
    margin-right: 10px;
    font-size: 22px;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single_address:hover i {
    background:  rgb(10, 170, 170);
    cursor: pointer;
    color: #fff;
}

.single_address h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
}

.single_address p {
    margin-top: 5px;
}

.section-title h1 {
    font-size: 44px;
    font-weight: 500;
    margin-top: 0;
    position: relative;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.section-title p {
    padding: 0 10px;
    width: 70%;
    margin: auto;
    letter-spacing: 1px;
}
.section-title {
    margin-bottom: 40px;
}
.text-center {
    text-align: center!important;
}

.single_address_international {
    margin-bottom: 0px;
    padding-left: 40px;
    margin-top: 20px;

}

@media only screen and (max-width:768px) {
    .single_address_international {
        padding-left: 0px;
    }
}

.single_address_international i {
    background: #f6f6f6;
    margin-left: 0px;
    color: #0B406A;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    float: left;
    margin-right: 14px;
    font-size: 22px;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single_address_international:hover i {
    background:  rgb(10, 170, 170);
    cursor: pointer;
    color: #fff;
}

.single_address_international h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
}

.single_address_international p {
    margin-top: 5px;
}

.single_address_mail {
    margin-bottom: 10px;
    padding-left: 40px;
    margin-top: 20px;
}

@media only screen and (max-width:768px) {
    .single_address_mail {
        padding-left: 0px;
    }
}

.single_address_mail i {
    background: #f6f6f6;
    margin-left: 0px;
    color: #0B406A;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    float: left;
    margin-right: 14px;
    font-size: 22px;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single_address_mail:hover i {
    background:  rgb(10, 170, 170);
    cursor: pointer;
    color: #fff;
}

.single_address_mail h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
}

.single_address_mail p {
    margin-top: 5px;
}


.single_address_call {
    margin-bottom: 10px;
    padding-left: 40px;
    margin-top: 30px;

}

@media only screen and (max-width:768px) {
    .single_address_call {
        padding-left: 0px;
    }
}

.single_address_call i {
    background: #f6f6f6;
    margin-left: 0px;
    color: #0B406A;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    float: left;
    margin-right: 14px;
    font-size: 22px;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single_address_call:hover i {
    background:  rgb(10, 170, 170);
    cursor: pointer;
    color: #fff;
}

.single_address_call h4 {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 600;
}

.single_address_call p {
    margin-top: 5px;
}