.footer {
    background-color: #0B406A;
}
.footer_Stuff{
    text-decoration: none;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
}
a{
    text-decoration: none;
}
.footer_Stuff:hover{
    color: goldenrod;
}

.footer_item_stuff_brand{
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    text-align: center;
    margin-top: -85px;
    margin-left: 50px;
}
.footer_logo_desc {
    text-align: center;
    text-transform: none;
    margin-top: -30px;
    font-size: 23px;
    letter-spacing: 0px;
    color: white;
    font-weight: 600;
    margin-left: 50px;
}
.footer_logo_desc2 {
    text-align: center;
    text-transform: none;
    margin-top: -20px;
    font-size: 10px;
    letter-spacing: 0.5px;
    color: white;
    font-weight: 600;
    margin-left: 50px;
}
.footer_logo {
    display: block;
    margin: 10px auto;
    margin-left: -20px;

}

@media screen and (max-width: 990px) {
    .footer_item_stuff_brand {
        font-size: 24px;
        margin-top: 0;
    }
    .footer_logo_desc {
        font-size: 18px;
        margin-top: -25px;
    }
    .footer_logo_desc2 {
        font-size: 12px;
    }
    .footer_logo {
        display: block;
        margin: 10px auto;
    
    }
}

@media screen and (max-width: 576px) {
    .footer_item_stuff_brand, .footer_logo_desc, .footer_logo_desc2 {
        margin: 0;
        text-align: center;
    }
    .footer_item_stuff_brand {
        font-size: 20px;
    }
    .footer_logo_desc {
        font-size: 16px;
    }
    .footer_logo_desc2 {
        font-size: 8px;
    }
}
